/** @format */

import React from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  instaIcon,
  githubIcon,
  socirankIcon,
  googleIcon,
  appleIcon,
  youtubeIcon,
  facebookIcon2,
  pelegantIcon,
  aaGroupIcon,
} from "./../extension/img";
import {
  socirankText,
  bankText,
  foodText,
  hobbystarText,
  socirankWebText,
  collegeText,
  tutorText,
  pelegantText,
  tutorText2,
  aagroupText,
} from "./../extension/about";
import {
  socirankExp,
  bankExp,
  hobbystarExp,
  foodExp,
  socirankWebExp,
  tutoringExp,
  collegeExp,
  pElegantExp,
  tutoring2Exp,
  aaGroupExp,
} from "./../extension/portfolioImgs";

export const ExpMediaDesign = () => {
  return (
    <div>
      <div className="experienceDiv">
        <div className="experienceContainer">
          <p className="experienceTitle">SociRank</p>
          <img className="experienceImg" src={socirankExp} alt="Image" />
          <div className="experienceIconDiv">
            <a href="https://socirank.com" target="_blank">
              <img className="icon" src={socirankIcon} alt="LinkedIn" />
            </a>
            <a href="https://instagram.com/socirank" target="_blank">
              <img className="icon" src={instaIcon} alt="Image" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.socirank"
              target="_blank"
            >
              <img className="icon" src={googleIcon} alt="Image" />
            </a>
            <a
              href="https://apps.apple.com/us/app/socirank/id1628275604"
              target="_blank"
            >
              <img className="icon" src={appleIcon} alt="Image" />
            </a>
            <a
              href="https://github.com/Abu-Nabe/SociRankExtensions"
              target="_blank"
            >
              <img className="icon" src={githubIcon} alt="Image" />
            </a>
          </div>
          <p className="experienceText">{socirankText}</p>
        </div>

        <div className="experienceContainer">
          <p className="experienceTitle">P.Elegant</p>
          <img
            loading="lazy"
            className="experienceImg"
            src={pElegantExp}
            alt="Image"
          />
          <div className="experienceIconDiv">
            <a href="https://pelegant.shop/" target="_blank">
              <img className="icon" src={pelegantIcon} alt="Image" />
            </a>
            <a
              href="https://www.facebook.com/Kashmirartsemporuimofficial?mibextid=LQQJ4d"
              target="_blank"
            >
              <img className="icon" src={facebookIcon2} alt="Image" />
            </a>
            {/* <a href="https://github.com/Abu-Nabe/banking" target="_blank">
                <img className="icon" src={githubIcon} alt="Image" />
              </a> */}
          </div>
          <p className="experienceText">{pelegantText}</p>
        </div>

        <div className="experienceContainer">
          <p className="experienceTitle">AAG Group Services</p>
          <img
            loading="lazy"
            className="experienceImg"
            src={aaGroupExp}
            alt="Image"
          />
          <div className="experienceIconDiv">
            <a href="https://friendly-gumption-a2c47a.netlify.app/" target="_blank">
              <img className="icon" src={aaGroupIcon} alt="Image" />
            </a>
            <a href="https://www.youtube.com/watch?v=A7_T-Fk-jpM" target="_blank">
              <img className="icon" src={youtubeIcon} alt="Image" />
            </a>
          </div>
          
          <p className="experienceText">{aagroupText}</p>
        </div>

        <div className="experienceContainer">
          <p className="experienceTitle">Personal Bank</p>
          <img
            loading="lazy"
            className="experienceImg"
            src={bankExp}
            alt="Image"
          />
          <div className="experienceIconDiv">
            <a href="https://github.com/Abu-Nabe/banking" target="_blank">
              <img className="icon" src={githubIcon} alt="Image" />
            </a>
          </div>
          <p className="experienceText">{bankText}</p>
        </div>

        <div className="experienceContainer">
          <p className="experienceTitle">Food Scanner</p>
          <img
            loading="lazy"
            className="experienceImg"
            src={foodExp}
            alt="Image"
          />
          <div className="experienceIconDiv">
            <a
              href="https://www.youtube.com/watch?v=gALBM_urZr4"
              target="_blank"
            >
              <img className="icon" src={youtubeIcon} alt="Image" />
            </a>
            <a
              href="https://github.com/Abu-Nabe/foodscanner"
              target="_blank"
            >
              <img className="icon" src={githubIcon} alt="Image" />
            </a>
          </div>
          <p className="experienceText">{foodText}</p>
        </div>

        <div className="experienceContainer">
          <p className="experienceTitle">HobbyStar</p>

          <img
            loading="lazy"
            className="experienceImg"
            src={hobbystarExp}
            alt="Image"
          />

          <div className="experienceIconDiv">
            <a
              href="https://www.youtube.com/shorts/cgjstc8pins"
              target="_blank"
            >
              <img className="icon" src={youtubeIcon} alt="Image" />
            </a>
            <a href="https://github.com/Abu-Nabe/Hobbystar" target="_blank">
              <img className="icon" src={githubIcon} alt="Image" />
            </a>
          </div>

          <p className="experienceText">{hobbystarText}</p>
        </div>

        <div className="experienceContainer">
          <p className="experienceTitle">SociRank Website</p>

          <img
            loading="lazy"
            className="experienceImg"
            src={socirankWebExp}
            alt="Image"
          />

          <div className="experienceIconDiv">
            <a href="https://socirank.com" target="_blank">
              <img className="icon" src={socirankIcon} alt="LinkedIn" />
            </a>
            <a
              href="https://www.youtube.com/watch?v=8CuML5eH288"
              target="_blank"
            >
              <img className="icon" src={youtubeIcon} alt="Image" />
            </a>
          </div>

          <p className="experienceText">{socirankWebText}</p>
        </div>
        <div className="experienceContainer">
          <p className="experienceTitle">College Attendance</p>
          <img
            loading="lazy"
            className="experienceImg"
            src={collegeExp}
            alt="Image"
          />
          <div className="experienceIconDiv">
            <a href="https://github.com/Abu-Nabe/mybvp" target="_blank">
              <img className="icon" src={githubIcon} alt="Image" />
            </a>
          </div>
          <p className="experienceText">{collegeText}</p>
        </div>
      </div>
    </div>
  );
};

export default ExpMediaDesign;
